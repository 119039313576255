@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

.SubpageHeader{
  background-image: 
  linear-gradient(rgba(4,9,30,0.5), rgba(4,9,30,0.5)),
  url(./assets/banner.jpg);
}
